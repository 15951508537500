<template>
  <v-row justify="center" id="selectedCourseTests">
    <!-- Heading -->
    <v-col cols="12" sm="12" class="d-flex justify-space-between">
      <h1 class="text-subtitle-2 text-left d-flex align-center">
        <template v-if="publicPage">
          <v-icon class="mr-1" size="20" color="deep-purple">
            mdi-clipboard-clock
          </v-icon>
          Latest Tests:
        </template>
        <template v-else>
          <v-icon class="mr-2" color="pink" small>mdi-account-heart</v-icon>
          <span class="text-transform-capitalize">
            Tests For You - {{ selectedCourse }}
          </span>
        </template>
      </h1>
      <v-btn
        v-if="$route.name != 'Course' && tests.length > 4 && courses.length > 0"
        :to="{
          path: `/course/${allCoursesIndex(selectedCourse)}`,
        }"
        text
        small
        >View all <v-icon>mdi-chevron-right</v-icon></v-btn
      >
    </v-col>

    <!-- if Loading -->
    <v-row v-if="loading || superLoading" justify="center" class="mx-md-5">
      <v-col sm="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col sm="4" class="hidden-sm-and-down">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- if Error -->
    <v-row v-else-if="error" justify="center" class="my-3">
      <v-col cols="12" md="9">
        <v-alert type="warning" dense text>{{ error }}</v-alert>
      </v-col>
    </v-row>

    <!-- Else Data -->
    <v-col v-else cols="12" sm="12" class="pa-0">
      <!-- Empty State -->
      <v-alert v-if="!(allTests.length > 0)" type="info" dense text>
        No tests added for this course yet :(
      </v-alert>

      <!-- Slide with Tests -->
      <v-slide-group v-else>
        <v-slide-item v-for="test in allTests" :key="test.id">
          <!-- Test Card -->
          <v-card
            id="card-gradient"
            class="ma-3 pb-1 rounded-lg blue"
            :max-width="$vuetify.breakpoint.mobile ? 260 : '25.65vw'"
          >
            <v-list-item>
              <v-list-item-content>
                <!-- isBilingual / free or premium -->
                <div class="text-overline mb-4">
                  <!-- Free Chip -->
                  <v-chip
                    v-if="!test.isPremium"
                    color="green accent-3 white--text"
                    class="mr-2"
                    x-small
                  >
                    Free
                  </v-chip>

                  <v-icon
                    v-else
                    color="white darken-2"
                    class="mr-2 amber rounded-circle pa-1"
                    size="20"
                  >
                    mdi-crown-outline
                  </v-icon>

                  <!-- Language Chip -->
                  <v-chip class="white" x-small>
                    <span v-if="test.isBilingual"> Eng/Hin </span>
                    <span v-else-if="test.onlySecondaryLang"> Hindi </span>
                    <span v-else> English </span>
                  </v-chip>
                </div>

                <!-- Test name and time -->
                <v-list-item-title class="text-h5 white--text mb-1">
                  {{ test.testName }}
                </v-list-item-title>

                <v-list-item-subtitle class="mt-2 white--text">
                  <!-- Questions and Duration -->
                  <div>
                    <v-icon class="mr-1" small>mdi-card-text</v-icon>
                    <b>{{ test.questionCount }}</b> Questions

                    <v-icon class="mx-1" small>mdi-timer-sand</v-icon>
                    <b>{{ test.testDuration }}</b>
                    Mins
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <!-- Button (Unlock/Take Test) -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- Re-attempt and Result Button -->
              <template v-if="test.id in results">
                <v-btn
                  :color="
                    test.isPremium ? 'amber--text text--darken-4 px-3' : 'px-3'
                  "
                  elevation="1"
                  :to="
                    test.isPremium && !isUserSubscribed
                      ? '/premium-pass'
                      : {
                          name: 'Result',
                          params: { id: test.id },
                        }
                  "
                  rounded
                  small
                >
                  <template v-if="test.isPremium">
                    <span v-if="isUserSubscribed"> Re-Attempt / Solution </span>
                    <span v-else class="d-flex align-center">
                      <v-icon class="mr-2" size="17">mdi-lock</v-icon>Unlock
                    </span>
                  </template>
                  <template v-else> Result / Solution </template>
                </v-btn>
              </template>

              <!-- Attempt Button -->
              <v-btn
                v-else
                :color="
                  test.isPremium ? 'amber--text text--darken-4 px-3' : 'px-3'
                "
                elevation="1"
                :to="
                  test.isPremium && !isUserSubscribed
                    ? '/premium-pass'
                    : {
                        name: 'Test',
                        params: { id: test.id, testObj: test },
                      }
                "
                rounded
                small
              >
                <template v-if="test.isPremium">
                  <span v-if="isUserSubscribed">Attempt</span>
                  <span v-else class="d-flex align-center">
                    <v-icon class="mr-2" size="17">mdi-lock</v-icon>Unlock
                  </span>
                </template>
                <template v-else> Attempt </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-col>
  </v-row>
</template>

<script>
const dayjs = require("dayjs");

export default {
  name: "SelectedCourseTests",
  props: ["superLoading", "publicPage", "isUserSubscribed", "selectedCourse"],

  computed: {
    allTests() {
      var allTests = [];

      this.tests.forEach((testObj) => {
        // curr dateTime <= endDateTime
        if (
          testObj.isHighlighted &&
          dayjs().isSameOrBefore(dayjs(testObj.endDateTime.seconds * 1000))
        ) {
          allTests.push(testObj);
        } else if (!testObj.isHighlighted) {
          allTests.push(testObj);
        }
      });

      return allTests;
    },
  },

  data: () => ({
    loading: true,
    error: "",
    tests: [],
    courses: [], // get from Server
    results: {},
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    allCoursesIndex(courseName) {
      var index = 0;

      this.courses.some((courseObj, i) => {
        index = i;
        //array.some (breaks on truthy return value)
        return courseObj.courseName == courseName;
      });

      return index;
    },

    dateTimeText(timestamp) {
      return dayjs(timestamp).format("hh:mm a, D MMM");
    },

    fetchSelectedCourseTests() {
      // get courses array from store

      const selectedCourseTestsArr =
        this.selectedCourse in this.$store.getters.courseTests
          ? this.$store.getters.courseTests[this.selectedCourse]
          : [];

      const resultsObj = this.$store.getters.results;

      //if not found in store
      if (
        selectedCourseTestsArr.length <= 0 ||
        typeof selectedCourseTestsArr == "undefined"
      ) {
        //get course tests from server
        this.$store
          .dispatch("getCourseTests", this.selectedCourse)
          .then((res) => {
            if (res) {
              this.tests = res.testResArr;
              this.results = res.resultsResObj;
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.tests = selectedCourseTestsArr;
        this.results = resultsObj;
        this.setLoading(false);
      }
    },

    fetchCourses() {
      // get courses array from store
      const coursesArr = this.$store.getters.courses;

      //if not found in store
      if (coursesArr.length <= 0 || typeof coursesArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getMetaDoc")
          .then((res) => {
            if (res) {
              this.courses = res.courses;
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // if found in store
        this.courses = coursesArr;
        this.loading = false;
      }
    },
  },

  mounted() {
    this.fetchSelectedCourseTests();
    this.fetchCourses();
  },
};
</script>

<style scoped>
#selectedCourseTests #card-gradient {
  background: #fc5c7d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #666,
    #fb8fa6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top left,
    #666,
    #8f94fb
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>